import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import { appTheme } from './theme';
import { AuthContextProvider } from './context/AuthContext';

import { ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <ThemeProvider theme={appTheme}>
        <App />
      </ThemeProvider>
    </AuthContextProvider>
  </BrowserRouter>
);
