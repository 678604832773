import React, { useEffect, useState, useContext } from 'react';
import { Box, Button, FormControl, TextField, Typography, AppBar, Grid2 as Grid } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import useAuth from '../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import { axiosInstance } from '../api/apiConfig';

import blackbeardSvg from '../media/blackbeard.svg';

export default function LoginPage() {

    const { setAccessToken, setCSRFToken, setIsLoggedIn, setRefreshToken } = useAuth();
    const navigate = useNavigate()
    const location = useLocation()
    const fromLocation = location?.state?.from?.pathname || '/'
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    function onEmailChange(event) {
        setEmail(event.target.value)
        console.log(email)
    }

    function onPasswordChange(event) {
        setPassword(event.target.value)
    }

    async function onSubmitForm(event) {
        event.preventDefault()

        setLoading(true)

        try {
            const response = await axiosInstance.post('authentication/login', JSON.stringify({
                email,
                password
            }))

            setAccessToken(response?.data?.access_token)
            setRefreshToken(response?.data?.refresh_token)
            setCSRFToken(response.headers["x-csrftoken"])

            setIsLoggedIn(true);
            setEmail()
            setPassword()
            setLoading(false)

            navigate('/main')
            console.log(response);
        } catch (error) {
            setLoading(false)
            // TODO: handle errors
        }
    }
    

  return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
        }}
        id='login_page'
      >
        <AppBar position='fixed' sx={{ display: 'flex', flexDirection: 'row', boxShadow: 0, bgcolor: 'transparent', alignItems: 'flex-start', backgroundImage: 'none', mt: 2, pl: 2 }}>
            <a href="https://lksidegroup.com" style={{ display: 'flex', flexDirection: 'row', color: 'white', textDecoration: 'none'}}>
                <NavigateBeforeIcon />
                <HomeIcon />
                <Typography sx={{ pl: 1}}>Home</Typography>
            </a>
        </AppBar>
        <Box
          sx={{
            width: '100%',
            maxWidth: 400,
            textAlign: 'center',
            boxShadow: 3,
            padding: 4,
            borderRadius: 2,
            borderColor: 'secondary.dark',
            bgcolor: 'white',
          }}
        >
        
          <Typography variant="h4" color="primary.dark" sx={{ display: 'flex', alignItems: 'center' }} gutterBottom>
          <img src={blackbeardSvg} className='filter-darkblue' /> Blackbeard Login
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Let's get started! Please enter your details.
          </Typography>
          <form onSubmit={onSubmitForm}>
            <FormControl fullWidth margin="normal">
              <TextField label='E-Mail' variant='outlined' type='email' name='email' fullWidth margin='normal' onChange={onEmailChange} />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField label='Password' variant='outlined' type='password' name='password' fullWidth margin='normal' onChange={onPasswordChange} />
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginY: 2,
              }}
            >
            <Typography>Forgot password?</Typography>
            </Box>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Sign In
            </Button>
          </form>
        </Box>
        <Typography variant="body2" color="secondary.light" sx={{ marginTop: 2 }}>
          © Lakeside Group {new Date().getFullYear()}
        </Typography>
      </Box>
  );
}