import React, { useEffect, useState } from 'react'
import './App.css';
import LoginPage from './pages/loginPage';
import useAuth from './hooks/useAuth';
import useLogout from './hooks/useLogout';
import AuthMiddleware from './middleware';
import { useNavigate, Routes, Route, Navigate } from "react-router-dom"
import HomePage from './pages/homePage';


function App() {

  return(
    <Routes>
      <Route path='/' element={<LoginPage />} />
      <Route path='main' element={<AuthMiddleware />}>
        <Route index element={<HomePage />} />
      </Route>
    </Routes>
  )
}

export default App;
