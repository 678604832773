import { useState, useEffect, createContext } from 'react';
import Cookies from 'js-cookie';

export const AuthContext = createContext({
    user: {},
    setUser: () => { },
    accessToken: null,
    refreshToken: null,
    csrfToken: null,
    setAccessToken: () => { },
    setRefreshToken: () => { },
    setCSRFToken: () => { },
    isLoggedIn: false,
    setIsLoggedIn: () => { }
});

function getCookie(name) {
    if (!document.cookie) {
      return null;
    }
  
    const xsrfCookies = document.cookie.split(';')
      .map(c => c.trim())
      .filter(c => c.startsWith(name + '='));
  
    if (xsrfCookies.length === 0) {
      return null;
    }
    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
}

export function AuthContextProvider(props) {

    const [user, setUser] = useState({})
    const [accessToken, setAccessToken] = useState(JSON.parse(localStorage.getItem('accessToken')) || null)
    const [refreshToken, setRefreshToken] = useState(JSON.parse(localStorage.getItem('refreshToken')) || null)
    const [csrfToken, setCSRFToken] = useState(getCookie('X-CSRFTOKEN') || null)
    const [isLoggedIn, setIsLoggedIn] = useState(JSON.parse(localStorage.getItem('isLoggedIn')) || false)

    useEffect(() => {

        const csrf = Cookies.get('refresh');

        console.log(csrfToken + '-csrf');

        console.log('triggered-hook')

        localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
        localStorage.setItem('accessToken', JSON.stringify(accessToken));
        localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
    }, [isLoggedIn, accessToken, refreshToken])

    return (
        <AuthContext.Provider value={{
            user, setUser,
            accessToken, setAccessToken,
            refreshToken, setRefreshToken,
            csrfToken, setCSRFToken,
            isLoggedIn, setIsLoggedIn
        }}>
            {props.children}
        </AuthContext.Provider>
    )
};

export default AuthContext;