import React, { useEffect, useState } from 'react'
import useAuth from '../hooks/useAuth';
import useLogout from '../hooks/useLogout';
import { useNavigate, Routes, Route, Navigate } from "react-router-dom"
import useUser from '../hooks/useUser';


export default function HomePage() {

    const { isLoggedIn } = useAuth();
    const logout = useLogout();
    const navigate = useNavigate();
    const getUser = useUser();
  
    async function onLogout() {
  
      await logout()
      navigate('/')
  
    }
  
  
    useEffect(() => {
        getUser()
    }, []);
    
    return(
        <div className="App">
            Logged In<br />
            <button type="button" onClick={onLogout}>Logout</button>
        </div>
    );
  }